.campaign-container{
    display: grid;
    grid-template-columns: 1fr 6fr 4fr 2fr 4fr 2fr 1fr;
    border: 1px solid rgba(255,255,255,0.24);
    background-color: #222;
    border-radius: 1rem;
    box-sizing: border-box;
    padding: 8px 8px;
    gap: 8px;
    align-items: center;
    margin-top: 4px;
    justify-self: center;
    align-self: center;
    max-width: 1100px;
    width: 100%;
    margin-bottom: 12px;
}
.t-grey-label{
    color: rgba(255,255,255,0.6)
}

.t-grey-value{
    font-weight: 500;
}
.campaign-container.focused:hover{
    cursor: pointer;
    transition: 0.3s ease-in-out;
    background-color: #333;
}

.campaign-name-container{
    display: flex;
    flex-direction: column;
    text-overflow: ellipsis;
    overflow: hidden;
}

.campaign-id{
    color: rgb(147, 147, 147);
    font-size: 14px;
}

.campaign-id span{
    font-size: 11px;
    white-space: nowrap;
    padding: 2px 8px;
    border-radius: 16px;
    max-height: 24px;
    background-color: rgba(255,255,255,0.24);
    color: #fff;
    margin-left: 8px;
}

.span{
    font-size: 10px;
    padding: 3px 9px;
    white-space: nowrap;
    border-radius: 16px;
    max-height: 24px;
    background-color: rgb(255, 136, 0);
    color: #000000;
    margin-left: 8px;
}

.campaign-name{
    color: #fff;
    font-size: 18px;
    text-overflow: clip;
    max-width: 280px;
    font-weight: 500;
}

.campaign-status{
    width: 8px;
    height: 8px;
    background-color: red;
    justify-self: center;
    border-radius: 16px;
}

.copy-round-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    border: none;
    cursor: pointer;
    justify-self: center;
    background-color: #f9f9f9;
}

.copy-round-btn:hover{
    background-color: rgb(255, 130, 47);
    transition: 0.1s ease;
}


.copy-round-btn svg{
    width: 16px;
    height: 16px;
}