.queue-item{
    display: grid;
    grid-template-columns: 1fr 160px 3fr 256px 32px;
    gap: 12px;
    width: 100%;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
}

.queue-item div{

}

.queue-item:hover{
    background-color: #eaeaea;
    transition: 0.1s;
    cursor: pointer;
}

.ts{
    font-size: 11px;
}

.u{
    font-weight: 600;
}

.outline-key-span{
    background-color: #000;
    color:#FFF;
    padding: 8px 16px;
    box-sizing: border-box;
    white-space: nowrap;
    border-radius: 24px;
}

.failed-key-config{

    background-color: red;
        color:#FFF;
        width: fit-content;
        font-weight: 600;
        padding: 8px 16px;
        box-sizing: border-box;
        white-space: nowrap;
        border-radius: 24px;
}

.outline-proxy-span{
    height: auto;
    max-height: 36px;
    width: fit-content;
    background-color: #6418a2;
    color:#FFF;
    padding: 8px 16px;
    box-sizing: border-box;
    white-space: nowrap;
    border-radius: 24px;
}

.t-content.split{
    gap: 8px !important;
}

.s-block-heading{
    display: flex;
    flex-direction: row;
    gap: 4px;
    box-sizing: border-box;
    margin-top: 12px;
    margin-bottom: 4px;
}

.t-copy-btn{
    color: #000;
    background-color: #FFF;
    border: none;
    padding: 8px 16px;
    box-sizing: border-box;
    white-space: nowrap;
    border-radius: 24px;
    font-weight: 500;
    transition: 0.15s ease-in-out;
    cursor: pointer;
}

.t-copy-btn:disabled{
    opacity: 0.2;
    cursor: default;
}

.t-copy-btn:hover:disabled{
    transform: scale(1);
}

.t-copy-btn:active:disabled{
    transform: scale(1);
}

.t-copy-btn:hover{
    transform: scale(0.95);
}

.t-copy-btn:active{
    transform: scale(0.9);
}

.t-text-warning{
    color: #FFF;
    background-color: #454545;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 12px;
    width:fit-content;
}
