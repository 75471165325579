.t-container{
    background-color: #222;
    color: #fff;
    min-height: 100vh;
}

.t-container.t-container-center{
    justify-content: center;
    align-items: center;
    display: flex;
}

.t-double-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}

.t-content{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: 1100px;
    justify-self: center;
    align-self: center;
}

.custom-tooltip{
    border: 1px solid rgba(255,255,255,0.16);
    background-color: #FFF;
    padding: 8px 12px; 
    box-sizing: border-box;
    border-radius: 12px;
    color: #000;
    font-size: 14px;
}

.custom-tooltip-date{
    font-size: 12px;
    margin-bottom: 4px;
    /* text-align: right; */
}

.custom-tooltip-content{
    display: grid;
    color: #000;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}

.t-content.t-content-center{
    display: flex;
    align-items: center;
    align-self: center;
    justify-self: center;
    justify-content: center;
    min-height: 100vh;
    max-width: 400px;
    width: 100%;
}

.cards-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 8px;
    width: 100%;
}


@media screen and (max-width: 500px) {
    .cards-container{
        grid-template-columns: 1fr;
    }
}

.t-label{
    font-size: 16px;
    color: white;
    font-weight: 500;
}

.t-checkbox-label{
    font-size: 16px;
    color: rgba(255,255,255,0.6);
    margin-left: 8px;
    text-align: left;
}

.t-input-label{
    font-size: 16px;
    color: rgba(255,255,255,0.6);
    margin-bottom: -8px;
    text-align: left;
}
.t-input{
    background: rgba(255,255,255,0.16);
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    border-radius: 12px;
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid transparent;
    transition: 0.3s ease-in-out;
    outline: none;
    color: white;
    font-size: 16px;
}

textarea.t-input{
    padding-top: 8px;
}

.t-input::placeholder{
    color: rgb(171, 171, 171);
}

.t-input:focus{
    border: 1px solid white;
    background: rgba(255,255,255,0.24);
}

.t-button{
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding-left: 36px;
    padding-right: 36px;
    box-sizing: border-box;
    color: white;
    background-color: rgba(255,255,255,0.16);
    border: none;
    border-radius: 12px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.t-button.t-wide{
    width: 100%;
    box-sizing: border-box;
}

.t-button.t-primary{
    background-color: #526ed3;
}

.t-button.t-primary:hover{
    background-color:     #6c86e2
}

.t-button.t-danger{
    background-color: #DD4C1E;
}


.t-button.t-danger:hover{
    background-color: #F45725;
}

.t-button:hover{
    background-color: rgba(255,255,255,0.24);
}


.page-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.common-container{
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-columns:  1fr;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
.common-navigation{
    background-color: #333333;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    height: 100vh;
    z-index: 22;
    animation-name: fadeIn;
    animation-duration: 0.2s;
}


.navigation-header{
    color: white;
    margin-top: 24px;
    font-weight: 600;
    font-size: 48px;
}

.navigation-link{
    color: white;
    font-weight: 400;

    cursor: pointer;
    font-size: 16px;
    padding-left: 8px;
    text-decoration: none;
    padding-top: 8px;
    padding-bottom: 8px;
    transition: 0.1s;
}
.navigation-link:hover{
    background-color: white;
    color: #333;
}
.content-block{
    padding: 16px 32px;
    box-sizing: border-box;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 148px;
}

.migration-container{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr ;
    gap: 16px;
    margin-bottom: 8px;
}



@media screen and (max-width: 500px) {
    .content-block{
        padding: 4px 8px;
    }

    .migration-container{
        grid-template-columns: 1fr;
    }
}

.d-input{
    /* min-width: 250px; */
    padding: 6px 12px;
    width: 100%;
    font-size: 18px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #cdcdcd;
}
.d-button{
    border: none;
    background-color: rgb(60, 60, 60);
    color: white;
    font-weight: 600;
    border-radius: 8px;
    padding: 12px 12px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 18px;
    height: 50px;
    white-space: nowrap;
}
.d-button:hover{
    background-color: rgb(0, 0, 0);
    transition: 0.05s;
}

.d-button:active{
    background-color: rgb(0, 0, 0);
}

.create-qiwi-group{
    display: grid;
    grid-template-columns: 1fr 250px 150px;
    gap: 16px;
}
.create-deeplink-group{
    display: grid;
    grid-template-columns: 150px 150px 250px 150px;
    gap: 16px;
}
.create-promo-group{
    display: grid;
    grid-template-columns: 250px 250px 150px 150px;
    gap: 16px;
    /* max-width: 800px; */
}
.label{
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: bold;
}
.create-outline-group{
    display: grid;
    grid-template-columns: 1fr 250px 150px;
    gap: 16px;
}



.row-qiwi{
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr 4fr 1fr;
    border: 1px solid rgba(255,255,255,0.24);
    background-color: #222;
    border-radius: 1rem;
    box-sizing: border-box;
    padding: 8px 8px;
    gap: 8px;
    align-items: center;
    margin-top: 4px;
    justify-self: center;
    align-self: center;
    max-width: 1100px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 12px;
}

.row-qiwi:hover{
    cursor: pointer;
    transition: 0.3s ease-in-out;
    background-color: #333;
}

.row-qiwi.labels{
border: none;
}
.row-qiwi div input{
    border: 1px solid #cdcdcd;
    padding: 4px;
    box-sizing: border-box;
    border-radius: 4px;
}

.row-deeplink{
    display: grid;
    max-width: 1100px;
    width: 100%;
    align-self: center;
    grid-template-columns: 60px 2fr 3fr 1fr 1fr 48px;
    border: 1px solid rgba(255,255,255,0.24);
    background-color: #222;


    border-radius: 1rem;
    box-sizing: border-box;
    padding: 8px 8px;
    margin-bottom: 12px;
    gap: 8px;
}

.row-deeplink.labels{
    border: none !important;
    grid-template-columns: 72px 2fr 3fr 1fr 1fr 64px;
}

.overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
}

.overlay-content{
    width: 100%;
    max-width: 650px;
    box-sizing: border-box;
    padding: 24px;
    color: white;
    display: flex;
    background-color: #333;
    border-radius: 20px ;
    flex-direction: column;
    gap: 8px;
}

.overlay-content.overlay-content-server{
    max-width: 500px;
}

.server-label{
    font-size: 15px;
}

.server-label span{
    color: rgba(255,255,255, 0.5);
}
/* 
.overlay-content input{
    width: 100%;
    font-size: 18px;
    box-sizing: border-box;
    padding: 12px 8px;

}

.overlay-content button{
    padding: 12px 8px;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
} */

.deeplink-column{
    /* text-align: left; */

    display: flex;
    align-items: center;
    /* justify-content: center; */
    word-break: break-all;
}
.deeplink-column.center{
    justify-content: center;
}

.qiwi-column{
    text-align: left;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    height: 56px;
    word-break: break-all;
}


.qiwi-column span{
    margin-left: 8px;
    cursor: pointer;
}

.status-round{
    width: 10px;
    height: 10px;
    border-radius: 10px;

    justify-self: center;
    align-self: center;
    /* border: 1px solid grey; */
}

.status-round.green{

    justify-self: center;
    align-self: center;
    background-color: rgb(12, 235, 12);
}

.status-round.red{

    justify-self: center;
    align-self: center;
    background-color: rgb(214, 16, 16);
}

.status-round.yellow{

    justify-self: center;
    align-self: center;
    background-color: rgb(218, 168, 42);
}

.status-round.purple {
    justify-self: center;
    align-self: center;
    background-color: rgb(188, 11, 188);
}


.status-round.orange {
    justify-self: center;
    align-self: center;
    background-color: rgb(214, 115, 35);
}


.stats-container{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 100%;
    box-sizing: border-box;
    gap: 8px;
}


.stats-block{
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    background: #EAEAEA;
    /* height: 140px; */
    color: #333;
    border-radius: 24px;
}
.stats-label{
    font-size: 16px;
    white-space: nowrap;
}
.stats-value{
    font-size: 32px;
    font-weight: 300;
    color: #333;
    margin-top: 20px;
    white-space: nowrap;
}
.stats-value-red{
    font-size: 32px;
    font-weight: 300;
    color: rgb(186, 0, 0);
    margin-top: 20px;
}
.row-promo{
    display: grid;
    max-width: 1100px;
    font-size: 12px;
    margin-top: 12px;
    width: 100%;
    align-self: center;grid-template-columns: 1fr 6fr 4fr 2fr 4fr 2fr 1fr;

}

.t-left{
    text-align: left;
}
.login-container{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-center{
    padding: 12px;
    display: flex;
    flex-direction: column;
    width: 250px;
    gap: 8px;
    box-sizing: border-box;
    background-color: grey;
    border-radius: 8px;
}
.login-center input{
    font-size: 18px;
}
.login-center button{
    border: none;
    background: black;
    color: white;
    padding: 12px;
    cursor: pointer;
}
.orders-table{
    width: max-content;
}


.orders-row{
    display: flex;
    /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ; */
    gap: 8px;
    overflow: scroll;
    border-top: 1px solid #EAEAEA;
}
.orders-row:hover{
    background-color: #EAEAEA;
    font-weight: bold;
}
.orders-column{
    padding-top: 8px;
    padding-bottom: 8px;
    max-width: 350px;
    overflow: hidden;
    white-space: nowrap;
    border-right: 1px solid #EAEAEA;
}
.orders-100{
    width: 100px;
}


.orders-300{
    width: 350px;
}
.orders-200{
    width: 200px;
}

.orders-500{
    width: 500px;
}
.get-users-group{
    display: grid;
    grid-template-columns: 1fr 150px;
    align-items: center;
    font-weight: bold;
    gap: 8px;
    max-width: 1000px;
    white-space: nowrap;
}

.validation-group{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

.get-users-group button{
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    padding-top: 8px;
    padding-bottom: 8px;
}

.send-message-group{
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    gap: 8px;
    font-size: 18px !important;
}

.send-message-group textarea{
    height: 220px;
    resize: none;
}

.send-message-group button{
    height: 40px;
}


.charts-grid{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 12px;
    box-sizing: border-box;
}


.charts-grid div{
    /* padding: 9px; */
}

.recharts-responsive-container{
    /* padding: 8px; */

}

.custom-container{
    /* aspect-ratio: 1 / 1 !important; */
    box-sizing: border-box;
    border-radius: 12px;
    min-height: 400px;
    background-color: #333;
}

@media screen and (max-width: 800px) {
    .stats-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .recharts-wrapper{
        width: 100%;
    }
    .stats-value-red{

        font-size: 24px;
    }
    .stats-value{
        font-size: 24px;
    }
    .charts-grid{
        grid-template-columns: 1fr;
    }

    .overlay-content{
        padding: 8px;
        padding-top: 12px;
        
    }
}



.overlay-d-content{
    display: flex;
    width: 100%;
    position: relative;
    max-width: 600px;
    padding: 48px 12px ; 
    padding-bottom: 12px;
    box-sizing: border-box;
    flex-direction: column;

    background-color: white;
    border-radius: 8px;
    gap: 8px;
}

.overlay-row{
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 12px;
}

.overlay-close{
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 32px;
    border: none;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    cursor: pointer;
}

.overlay-close svg{
    width: 12px;
    height: 12px;
}

.migration-c{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

@media screen and (max-width: 750px) {
    .migration-c{
        grid-template-columns: 1fr;
    }
}

.validation-result{
    height: 100%;
    padding-top: 8px;
    box-sizing: border-box;
    align-items: flex-start;
    background-color: white;
    color: #363636;
    display: block !important;
}