body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Onest';
  font-weight: 400; /* Regular font weight */
  src: url('./assets/fonts/Onest/WOFF/Onest-Regular.woff') format('woff'),
       url('./assets/fonts/Onest/TTF/Onest-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Onest';
  font-weight: 500; /* Regular font weight */
  src: url('./assets/fonts/Onest/WOFF/Onest-Medium.woff') format('woff'),
       url('./assets/fonts/Onest/TTF/Onest-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Onest';
  font-weight: 600; /* Regular font weight */
  src: url('./assets/fonts/Onest/WOFF/Onest-Bold.woff') format('woff'),
       url('./assets/fonts/Onest/TTF/Onest-Bold.ttf') format('truetype');
}

*{
  font-family: 'Onest', sans-serif;
}

.nav-btn{
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 24px;
  z-index: 50;
  font-size: 36px;
  color: white;
  background-color: white;
  box-shadow: 2px 5px 50px 15px rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  border-radius: 40px;
  justify-content: center;
  cursor: pointer;
  transition: 0.1s;
}
.nav-btn:hover{
  margin: 19px;
  width: 70px;
  height: 70px;
}
.nav-btn:active{
  margin-bottom: 14px;
}