.s-card-template{
    height: 150px;
    box-sizing: border-box;
    padding: 21px;
    display: flex;
    flex-direction: column;
    background-color: #333;
    /* width: 240px; */
    border-radius: 12px;
    gap: 0;
    margin: 0;
}

.s-card-heading{
    font-size: 20px;
    color: white;
    margin: 0;
}

.s-card-value{
    font-size: 22px;
    color: white;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    margin-top: 12px;
}

.span-green{
    color: rgb(50, 205, 58);
    font-size: 20px;
}

.span-red{
    color: rgb(248, 19, 19);
     font-size: 20px;
}


.s-card-subheading{
    font-size: 14px;
    margin: 0;
    margin-top: -4px;
    color: rgb(147, 147, 147);
}



@media screen and (max-width: 500px) {
    .s-card-template{
        width: 100%;
    }
}